import { useEffect, useState, useContext, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { DragDrop } from "@uppy/react";
import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/status-bar/dist/style.css";
import Uppy from '@uppy/core';
import UppyGerman from '@uppy/locales/lib/de_DE';
import UppyEnglish from '@uppy/locales/lib/en_US';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from "@material-ui/core";
import XHRUpload from "@uppy/xhr-upload";
import { useUppy, StatusBar } from "@uppy/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink, faFile, faLink, faSpinnerThird, faTimes, faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { toast } from "react-toastify";
import { NetworkChangesContext } from "../core/networkChanges.js";
import { useHistory } from "react-router";
import { StatusContext } from "../core/contextStatus.js";
import { Alert } from "@material-ui/lab";
import { LoginContext } from "../core/contextLogin.js";


function FormMyDocuments(props) {

    const { t, i18n } = useTranslation();

    const [files, setFiles] = useState([]);
    const history = useHistory();
    const { setStatusFiles } = useContext(StatusContext)
    const { user, getApplicant } = useContext(LoginContext);
    const uppy = useUppy(() => {
        return new Uppy({
            restrictions: {
                maxFileSize: 15000000,
                maxNumberOfFiles: 10,
                allowedFileTypes: [".pdf", ".jpg", ".jpeg", ".png"],
                locale: i18n.language !== "de-DE" ? UppyEnglish : UppyGerman
            },
            autoProceed: true
        }).use(XHRUpload, {
            endpoint: "/api/applicant/upload"
        }).on("complete", async (result, result1) => {
            toast(t('myFiles.success'), { type: "success" });
            setStatusFiles(true)
            await updateFiles();
        }).on('error', (error) => {
            console.error(error.stack);
        }).on('upload-error', (file, error, response) => {
            console.log('error with file:', file.id);
            console.log('error message:', error);
        }).on('restriction-failed', (file, error) => {
            toast(
                <div>
                    {t('myFiles.errorFileType')} <b>{file.name}</b>
                </div>,
                { type: "warning" }
            );
        })
    }, [t]);

    const updateFiles = useCallback(async () => {
        try {
            const url = new URL("/api/applicant/files", window.location + "");
            const response = await fetch(url, {
                method: "GET",
                credentials: "same-origin",
            });
            const _files = await response.json();
            setFiles(_files);
        } catch (err) {
            console.log(err);
            toast(t('myFiles.error'), { type: "error" });
        }
    }, [])

    const resetUppy = useCallback(() => {
        uppy.reset();
    }, [uppy]);

    const deleteFile = async (_id) => {
        try {
            const url = new URL("/api/applicant/files/" + _id, window.location + "");
            const response = await fetch(url, {
                method: "DELETE",
                credentials: "same-origin",
            });
            const _files = await response.json();
            setFiles(_files);
            toast(t('myFiles.successDeleted'), { type: "success" });
        } catch (err) {
            console.log(err);
            toast(t('myFiles.errorDeleted'), { type: "error" });
        }
    };

    useEffect(() => {
        updateFiles();
    }, [updateFiles])

    useEffect(() => {
        try {
            const filesPending = files?.filter(file => file.state === 'queue') || []
            if (filesPending?.length > 0) {
                const timeout = setTimeout(() => {
                    updateFiles();
                }, 5000)
                return () => { 
                    clearTimeout(timeout)
                }
            }
        } catch (err) {
            console.log(err)
        }
        
    }, [files, updateFiles])

    return (
        <div className="div">
            <h1 className="heading">{t('myFiles.title')}</h1>

            <div className="form-grid">
                {files?.length === 0 && <div className={"one"}>{t('myFiles.noFiles')}</div>}
                {files?.length > 0 && (
                    <div className={"one"}>
                        <Typography>{t('myFiles.files')}</Typography>

                        <TableContainer component={Paper} className={"mt-3"}>
                            <Table aria-label="Tabelle mit Anhängen">
                                <TableBody>
                                    {files.map((row) => (
                                        <TableRow key={row._id}>
                                            <TableCell>
                                                {row.state === 'ok' && <div>
                                                    <FontAwesomeIcon icon={faFile} fixedWidth />&nbsp;
                                                    <a
                                                        href={"/api/applicant/file/" + row._id}
                                                        download={row.fileName + "." + row.fileType}
                                                    >
                                                        {row.fileName}
                                                    </a>
                                                </div>}
                                                {row.state !== 'ok' && <div>
                                                    <FontAwesomeIcon icon={faFile} fixedWidth />&nbsp;
                                                    {row.fileName}
                                                </div>}
                                            </TableCell>
                                            <TableCell>

                                            </TableCell>
                                            <TableCell align="right">
                                                {row.state === 'ok' && <div>
                                                    <Button
                                                        onClick={() => {
                                                            deleteFile(row._id);
                                                        }}
                                                        variant={"contained"}
                                                        startIcon={<FontAwesomeIcon icon={faTimes} />}
                                                        disabled={props.disabled}
                                                    >
                                                        {t('myFiles.delete')}
                                                    </Button>
                                                </div>}
                                                {row.state === 'queue' && <div>
                                                    <FontAwesomeIcon icon={faSpinnerThird} spin fixedWidth /> Datei wird überprüft...
                                                </div>}
                                                {row.state === 'nok' && <div>
                                                    <FontAwesomeIcon icon={faTriangleExclamation} style={{ color: 'red' }} fixedWidth /> Datei enthält Schadsoftware und wurde gelöscht.
                                                </div>}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )}

                {!props.disabled && (
                    <div className={"one mt-4"}>

                        <DragDrop
                            width="100%"
                            height="100%"
                            uppy={uppy}
                            locale={i18n.language !== "de-DE" ? UppyEnglish : UppyGerman}
                            disabled={props.disabled}
                            note={t('myFiles.note')}
                        />

                        <StatusBar
                            uppy={uppy}
                            locale={i18n.language !== "de-DE" ? UppyEnglish : UppyGerman}
                            hideAfterFinish={false}
                            showProgressDetails
                            doneButtonHandler={resetUppy}
                        />

                        <Alert severity="info" className="mb-4">
                            <span dangerouslySetInnerHTML={{__html: t('myFiles.note2')}}></span>
                            <span>&nbsp;<FontAwesomeIcon icon={faExternalLink} fixedWidth /></span>
                        </Alert>


                    </div>
                )}
            </div>

            <Button
                className={"mt-5"}
                color={"primary"}
                variant={"contained"}
                onClick={() => {
                    history.push("/weiteres");
                }}
                type={"button"}
            >
                {t('myFiles.next')}
            </Button>
        </div >
    );
}

export default FormMyDocuments;
