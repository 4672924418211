import { useContext } from "react";
import { useTranslation } from 'react-i18next';
import { faUser, faUserSlash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoginContext } from "../core/contextLogin";
import { NetworkChangesContext } from "../core/networkChanges.js";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";


function NavigationLoginLogout() {

    const { t } = useTranslation('');
    const theme = useTheme();
    const { authenticated, logout } = useContext(LoginContext);
    const { update } = useContext(NetworkChangesContext);
    const history = useHistory();

    return (


        authenticated ? (
            <div className="menu-item text-muted loginlogout">
                <span className="nav-icon">
                    <FontAwesomeIcon fixedWidth icon={faUserSlash} />
                </span>
                <span
                    onClick={async () => {
                        await logout();
                        await update();
                        history.push('/')
                        update();
                    }}
                >
                    {t('register.logout')}
                </span>
            </div>
        ) : (
            <Link className="menu-item pointer loginlogout" to={'/anmelden'} style={{ color: theme.palette.text.primary }}>
                <div className="nav-icon">
                    <FontAwesomeIcon fixedWidth icon={faUser} />
                </div>
                <div>{t('register.login')}</div>
            </Link>
        )

    );
}

export default NavigationLoginLogout;
