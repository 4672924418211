import React from "react";
import { useTranslation } from 'react-i18next';
import { faBusinessTime, faComments, faIdCardAlt, faShieldCheck, faThList } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";

function Help() {

    const { t } = useTranslation('');

    const ApplicationIntro = (props) => {
        return (
            <>
                <h1 className="heading">{t('help.title')}</h1>

                <Typography component={"p"} className={"mb-2"}>
                    {t('help.jobs.1')} <Link to="stellen">
                        <FontAwesomeIcon fixedWidth icon={faThList} /> {t('help.jobsButton')}
                    </Link> {t('help.jobs.2')}
                </Typography>

                <Typography component={"p"} className={"mb-2"}>
                    {t('help.aboutMe.1')} <Link to="ueber-mich">
                        <FontAwesomeIcon fixedWidth icon={faIdCardAlt} />  {t('help.aboutMeButton')}
                    </Link> {t('help.aboutMe.2')}

                </Typography>

                <Typography className={"mb-2"}>
                    {t('help.documents.1')} <Link to="meine-dokumente">
                        <FontAwesomeIcon fixedWidth icon={faIdCardAlt} /> {t('help.documentsButton')}
                    </Link> {t('help.documents.2')}

                </Typography>

                <Typography component={"p"} className={"mb-2"}>
                    {t('help.misc.1')} <Link to="weiteres">
                        <FontAwesomeIcon fixedWidth icon={faComments} /> {t('help.miscButton')}
                    </Link> {t('help.misc.2')}
                </Typography>

                <Typography component={"p"} className={"mb-2"}>
                    {t('help.status.1')} <Link to="status-der-bewerbung">
                        <FontAwesomeIcon fixedWidth icon={faBusinessTime} /> <b>{t('help.statusButton')}</b>
                    </Link> {t('help.status.2')}
                </Typography>

                <Typography component={"p"} className={"mb-2"}>
                    {t('help.settings.1')} <Link to="einstellungen">
                        <FontAwesomeIcon fixedWidth icon={faShieldCheck} /> {t('help.settingsButton')}
                    </Link> {t('help.settings.2')}
                </Typography>
            </>
        );
    };

    return (
        <div>
            <ApplicationIntro />
        </div>
    );
}

export default Help;
