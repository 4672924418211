import React, { useContext } from "react";
import { useTranslation } from 'react-i18next';
import { faBalanceScaleRight, faFileShield } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { ThemeContext } from "../core/contextTheme";

function NavigationLower() {

    const { navSwitch } = useContext(ThemeContext)

    const { t } = useTranslation('');


    return (
        <React.Fragment>

            <Link className="menu-item" to="/impressum">
                <span className="nav-icon text-muted"><FontAwesomeIcon fixedWidth icon={faBalanceScaleRight} /></span>
                <span className="text-muted">{t('navigation.impress')}</span>
            </Link>
            <Link className="menu-item" to="/datenschutz">
                <span className="nav-icon text-muted"><FontAwesomeIcon fixedWidth icon={faFileShield} /></span>
                <span className="text-muted">{t('navigation.privacy')}</span>
            </Link>

            {navSwitch()}

        </React.Fragment>
    )

}

export default NavigationLower