import { useCallback, useContext, useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoginContext } from "../core/contextLogin";
import { NetworkChangesContext } from "../core/networkChanges.js";
import { Button, TextField, Typography, useTheme } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";

function Settings() {

    const { t } = useTranslation('');

    const theme = useTheme();
    const { createPassword } = useContext(LoginContext);
    const { update } = useContext(NetworkChangesContext);

    const deleteDate = 59;

    const getIcon = (check) =>
        check ? (
            <FontAwesomeIcon color={theme.palette.success.main} fixedWidth icon={faCheck} />
        ) : (
            <FontAwesomeIcon fixedWidth icon={faTimes} />
        );

    const [workflows, setWorkflows] = useState([]);
    const [applicant, setApplicant] = useState({});

    const loadWorkflows = useCallback(async () => {
        const url = new URL("/api/workflows", window.location + "");
        const response = await fetch(url, { headers: { "Content-Type": "application/json" }, method: "GET" });
        const result = await response.json();
        setWorkflows(result);
    }, []);

    const loadApplicant = useCallback(async () => {
        const url = new URL("/api/auth/whoami", window.location + "");
        const response = await fetch(url, { headers: { "Content-Type": "application/json" }, method: "GET" });
        const result = await response.json();
        setApplicant(result);
    }, []);

    useEffect(() => {
        loadWorkflows();
        loadApplicant();
    }, [loadWorkflows, loadApplicant]);

    const {
        control,
        handleSubmit,
        watch,
        formState: { isDirty, isValid, errors },
    } = useForm({
        mode: "all",
        defaultValues: {
            password: "",
            password2: "",
        },
    });

    const onSubmit = async (data) => {
        try {
            await createPassword({ password: data.password });
            await update();
            await loadApplicant();
            toast(t('settings.successpassword'), { type: "success" });
        } catch (err) {
            console.log(err);
            toast(err.message, { type: "error" });
        }
    };

    const password = useRef({});
    password.current = watch("password", "");

    return (
        <div>
            <h1 className="heading">{t('settings.title')}</h1>
            <Typography component={"p"} className={"mb-2"}>
                {t('settings.p1')}
            </Typography>

            <Typography component={"p"} className={"mb-2"}>
                {t('settings.p2')}
            </Typography>

            {workflows.length === 0 && (
                <>
                    {" "}
                    {getIcon(false)} {t('settings.noworkflows.0')} {deleteDate} {t('settings.noworkflows.1')}
                </>
            )}
            {workflows.length > 0 && (
                <> {getIcon(true)} {t('settings.workflows')}</>
            )}

            {!applicant.registered && applicant.mail?.length > 0 && (
                <form onSubmit={handleSubmit(onSubmit)} className={"form-grid w-100 mt-5"}>
                    <div className="one">
                        <h3>{t('settings.createpassword.title')}</h3>
                        <Typography component={"p"} className={"mb-2"}>
                            {t('settings.createpassword.p1')}            
                        </Typography>
                    </div>
                    <div className="two">
                        <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: true,
                                minLength: {
                                    value: 6,
                                    message: t('settings.createpassword.error.0')
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    label={t('settings.createpassword.password.0')}
                                    type={"password"}
                                    size={"small"}
                                    {...field}
                                    variant={"filled"}
                                />
                            )}
                        />

                        <Controller
                            name="password2"
                            control={control}
                            defaultValue=""
                            rules={{
                                validate: (value) =>
                                    value === password.current ||
                                    t('settings.createpassword.error.1'),
                                required: true,
                                minLength: {
                                    value: 6,
                                    message: t('settings.createpassword.error.0'),
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    label={t('settings.createpassword.password.1')}
                                    type={"password"}
                                    size={"small"}
                                    variant={"filled"}
                                    {...field}
                                />
                            )}
                        />

                        {(errors.password || errors.password2) && (
                            <p style={{ color: "darkorange" }} className={"m-1 small"}>
                                {errors.password?.message}
                                {errors.password2?.message}
                            </p>
                        )}

                        <div className="one">
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                disabled={!isDirty || !isValid}
                                className={"mt-3"}
                                type="submit"
                            >
                                {t('settings.createpassword.password.create')}
                            </Button>
                        </div>
                    </div>
                </form>
            )}

            {applicant.registered && (
                <div className=" mt-5">
                    <h3>{t('settings.createpassword.title')}</h3>
                    <Typography component={"p"} className={"mb-2"}>
                        {t('settings.createpassword.created')}
                    </Typography>
                </div>
            )}

            <Typography component={"p"} className={"mt-5 mb-2"}>
                {t('settings.delete.p1')}
            </Typography>

            <Button color={"primary"} variant={"contained"}>
                {t('settings.delete.button')}
            </Button>
        </div>
    );
}

export default Settings;
