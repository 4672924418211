import React from "react";
import { useTranslation } from 'react-i18next';
import classnames from 'classnames'

function NavigationLanguage() {

    const { i18n } = useTranslation();

    const LangSelector = (_props) => {
        const active = (i18n.language === _props.lang ? 'flag-active' : '')
        return <div onClick={() => i18n.changeLanguage(_props.lang)} className={classnames("m-1 d-inline-block pointer flag", active)}>
            <span className={classnames("fi", "fi-" + _props.lang.substring(3).toLocaleLowerCase())}></span>
        </div>
    }

    return (
        <div>

            <LangSelector lang={'de-DE'} />
            <LangSelector lang={'en-US'} />

        </div>
    );
}

export default NavigationLanguage;
