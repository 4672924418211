import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBusinessTime,
    faCommentQuestion,
    faComments,
    faFile,
    faFilePdf,
    faIdCardAlt,
    faListCheck,
    faQuestionCircle,
    faShieldCheck,
    faThList
} from "@fortawesome/pro-solid-svg-icons";

import {
    faSquare,
    faSquareCheck
} from "@fortawesome/pro-light-svg-icons";
import { Link, useLocation } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";

import classnames from "classnames";
import { useContext } from "react";
import { LoginContext } from "../core/contextLogin";
import React, { useCallback } from "react";

import { useTranslation } from 'react-i18next';
import { StatusContext } from "../core/contextStatus";

function Navigation() {

    const { t } = useTranslation('');
    const location = useLocation();
    const theme = useTheme();
    const { authenticated } = useContext(LoginContext);
    const { statusApplied, statusAboutMe, statusFiles, statusMiscellaneous } = useContext(StatusContext);
    const getIcon = useCallback((check, iconOk, iconOptional = false) => {
        const ok = <FontAwesomeIcon color={theme.palette.text.secondary} fixedWidth icon={iconOk} />;
        const nok = <FontAwesomeIcon color={theme.palette.text.secondary} fixedWidth icon={iconOptional} />;
        return check ? (ok) : (nok)
    },
        [theme.palette.text.secondary]
    );

    const link = (link, isActive, icon, title, extraClasses, status) => {
        return (

            <Link key={link} className={classnames((isActive ? "item-active" : ""), extraClasses, "menu-item pointer")} to={link} style={{ color: theme.palette.text.primary }}>
                <div className="nav-icon">
                    <FontAwesomeIcon fixedWidth icon={icon} />
                </div>
                <div>{title}</div>
                <div className="ml-2">
                    {(status === true || status === false) && getIcon(status, faSquareCheck, faSquare)}
                </div>
            </Link>
        );
    };

    const nav = [
        { title: t('navigation.jobs'), icon: faListCheck, link: "/stellen", status: statusApplied },
        { title: t('navigation.aboutMe'), icon: faIdCardAlt, link: "/ueber-mich", auth: true, extraClasses: "mt-3", status: statusAboutMe, },
        { title: t('navigation.myDocuments'), icon: faFile, link: "/meine-dokumente", auth: true, status: statusFiles },
        { title: t('navigation.misc'), icon: faCommentQuestion, link: "/weiteres", auth: true, status: statusMiscellaneous },
        { title: t('navigation.status'), icon: faBusinessTime, link: "/status-der-bewerbung", auth: true, extraClasses: "mt-3 mb-3" },
        { title: t('navigation.settings'), icon: faShieldCheck, link: "/einstellungen", auth: true },
        { title: t('navigation.help'), icon: faQuestionCircle, link: "/hilfe", auth: true },
    ];

    return (
        nav.map((n) => {
            const item = link(
                n.link,
                location.pathname.indexOf(n.link) > -1,
                n.icon,
                n.title,
                n.extraClasses,
                n.status
            );


            if ((n.auth && authenticated) || !n.auth) return item;
            else return <React.Fragment key={n.link}></React.Fragment>;

        })
    );
}

export default Navigation;
