import { faBracketsCurly, faBrowser, faEnvelope, faFire, faGavel } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

function Imprint() {

    const devLink = <><FontAwesomeIcon icon={faBrowser} fixedWidth /> <a href='https://www.danieldebuhr.de'>www.danieldebuhr.de</a></>
    const devMail = <><FontAwesomeIcon icon={faEnvelope} fixedWidth /> <a href='mailto:info@danieldebuhr.de?subject=InfoNet Portal'>info@danieldebuhr.de</a></>
    const fire = <FontAwesomeIcon icon={faFire} color='red' fixedWidth />
    const terminal = <FontAwesomeIcon icon={faBracketsCurly} color='red' fixedWidth />
    const paragraph = <FontAwesomeIcon icon={faGavel} color='red' fixedWidth />

    return (
        <div>
            <h1 className="heading">Impressum</h1>

            <h3 className='mt-4'>{terminal} Entwicklung</h3>
            <p>Daniel de Buhr, IT-Beratung und Softwareentwicklung</p>
            <p>Verantwortlich für diese Webpräsenz.</p>
            <p>{devLink}<br />{devMail}</p>

            <h3 className='mt-4'>{fire} Open Source</h3>
            <p>Vielen Dank an die großartige JavaScript Open-Source Community, ohne die dieses Projekt<br />
      nicht möglich gewesen wäre.</p>


            <h3>{paragraph} Angaben gemäß § 5 TMG</h3>

            <p>
                Caritas Oldenburg gGmbH<br />
                Peterstraße 6<br />
                26121 Oldenburg<br />
                <a href='mailto:info@caritas-ol.de'>info@caritas-ol.de</a>
            </p>



        </div>
    )

}

export default Imprint;