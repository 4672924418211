import React, { useState, useCallback, useEffect, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider, Controller } from "react-hook-form";
import { createSelect, createCheckboxes, createInput } from "../core/creaters.js";
import { Typography, Button } from "@material-ui/core";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { faStar } from "@fortawesome/pro-solid-svg-icons";
import { faStar as faStarBorder } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StatusContext } from "../core/contextStatus.js";
import { LoginContext } from "../core/contextLogin.js";



const defaultValues = {
    rating: undefined,
    apprenticeQualification: false,
    interviewText: "",
    hearedAboutUs: ""
};

const Star = (props) => <FontAwesomeIcon icon={faStar} color={props.color} />;
const StarBorder = (props) => <FontAwesomeIcon icon={faStarBorder} color={props.color} />;

const StarButton = ({ active, onClick, disabled }) => (
    <button type="button" className="starButton" onClick={onClick}>
        {active ? (
            <Star color={disabled ? "gray" : "orange"} />
        ) : (
            <StarBorder color={disabled ? "gray" : "black"} />
        )}
    </button>
);

const StarField = ({ value, onChange, disabled }) => (
    <>
        <StarButton
            active={value >= 1}
            onClick={() => {
                if (!disabled) onChange(1);
            }}
            disabled={disabled}
        />
        <StarButton
            active={value >= 2}
            onClick={() => {
                if (!disabled) onChange(2);
            }}
            disabled={disabled}
        />
        <StarButton
            active={value >= 3}
            onClick={() => {
                if (!disabled) onChange(3);
            }}
            disabled={disabled}
        />
        <StarButton
            active={value >= 4}
            onClick={() => {
                if (!disabled) onChange(4);
            }}
            disabled={disabled}
        />
        <StarButton
            active={value >= 5}
            onClick={() => {
                if (!disabled) onChange(5);
            }}
            disabled={disabled}
        />
    </>
);


function FormMiscellaneous(props) {

    const { t } = useTranslation();

    const { setStatusMiscellaneous } = useContext(StatusContext)

    const hearedAboutUs = [
        { value: "AfA", label: t('formMiscellaneous.hearedAboutUs.AfA') },
        { value: "Empfehlung", label: t('formMiscellaneous.hearedAboutUs.Empfehlung') },
        { value: "Webseite", label: t('formMiscellaneous.hearedAboutUs.Webseite') },
        { value: "Online-Stellenbörse", label: t('formMiscellaneous.hearedAboutUs.OnlineStellenboerse') },
        { value: "Zeitungsanzeige", label: t('formMiscellaneous.hearedAboutUs.Zeitungsanzeige') },
        { value: "Aushang", label: t('formMiscellaneous.hearedAboutUs.Aushang') },
        { value: "Interne Bewerbung", label: t('formMiscellaneous.hearedAboutUs.InterneBewerbung') },
        { value: "Facebook", label: t('formMiscellaneous.hearedAboutUs.Facebook') },
        { value: "Instagram", label: t('formMiscellaneous.hearedAboutUs.Instagram') },
        { value: "Indeed", label: t('formMiscellaneous.hearedAboutUs.Indeed') },
        { value: "Jobportal Oldenburger-Münsterland", label: t('formMiscellaneous.hearedAboutUs.JobportalOldenburgerMuensterland') },
        { value: "Andere...", label: t('formMiscellaneous.hearedAboutUs.Andere') },
    ];

    const methods = useForm({
        defaultValues,
    });

    const [initialData, setInitialData] = useState(false);
    const history = useHistory();
    const { user, getApplicant } = useContext(LoginContext);

    const onSubmit = (data) => {
        setStatusMiscellaneous(data?.hearedAboutUs?.length > 0 || data.apprenticeQualification === true || data.apprenticeQualification === false || data.rating > -1)
        save(data);
    }

    useEffect(() => {
        if(user) {
            setInitialData(user)
        }
    }, [user]);

    useEffect(() => {
        if (initialData) {
            methods.reset(initialData);
            setInitialData(false);
        }
    }, [initialData, methods]);

    const save = async (data) => {
        try {
            const url = new URL("/api/applicant", window.location + "");
            await fetch(url, {
                headers: { "Content-Type": "application/json" },
                method: "POST",
                body: JSON.stringify(data),
            });
            toast(t('formMiscellaneous.success'), { type: "success", autoClose: 1000, position: "top-right" });
            await getApplicant();
            setTimeout(() => {
                history.push('/status-der-bewerbung');
            }, 1);

        } catch (err) {
            console.log(err);
            toast(t('formMiscellaneous.error'), "error");
        }
    };

    return (
        <div>
            <FormProvider  {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>

                    <h1 className="heading">{t('formMiscellaneous.title')}</h1>

                    <p>Wenn du magst, kannst du noch folgende Fragen beantworten:</p>

                    <div className="form-grid">

                        <div className="one mb-3">
                            {createSelect(
                                "hearedAboutUs",
                                t('formMiscellaneous.hearedAboutUsString'),
                                methods.control,
                                hearedAboutUs,
                                undefined,
                                undefined,
                                { disabled: props.disabled }
                            )}
                        </div>

                        <div className="one">
                            <div>
                                <Typography component="p">{t('formMiscellaneous.rating')}</Typography>
                                <Controller
                                    control={methods.control}
                                    name="rating"
                                    render={({ field }) => <StarField disabled={props.disabled} {...field} />}
                                />
                            </div>
                        </div>

                        <div className="one mb-3">
                            {createCheckboxes("apprenticeQualification", methods.control, {
                                color: "primary",
                                disabled: props.disabled,
                            })}
                            <label>{t('formMiscellaneous.apprenticeQualification')}</label>
                        </div>

                    </div>

                    <Button
                        className={"mt-5"}
                        color={"primary"}
                        variant={"contained"}
                        //onClick={() => saveForm(saveButtonTarget)}
                        type={"submit"}
                    >
                        {t('formMiscellaneous.next')}
                    </Button>

                </form>

            </FormProvider>

        </div>
    );
}


export default FormMiscellaneous;
