import React, { useState, useContext, useCallback, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from "react-hook-form";
import { createDatePicker, createInput } from "../core/creaters.js";
import { TextField, Typography, Button } from "@material-ui/core";
import { LoginContext } from "../core/contextLogin";
import LanguageSelector from "../components/LanguageSelector.js";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { StatusContext } from "../core/contextStatus.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBirthdayCake, faLanguage, faMapLocation, faPhone } from "@fortawesome/pro-solid-svg-icons";


const defaultValues = {
    salutation: "none",
    birthday: null,
    street: "",
    number: "",
    postalCode: "",
    city: "",
    phone: "",
    mobile: "",
    callbackVia: "",
    language: [
        {
            type: "",
            language: "",
        },
    ],
};

function FormAboutMe(props) {

    const { t } = useTranslation();

    const { setStatusAboutMe } = useContext(StatusContext)

    const methods = useForm({
        defaultValues,
    });

    const [initialData, setInitialData] = useState(false);
    const history = useHistory();

    const { user } = useContext(LoginContext);

    const onSubmit = (data) => {
        setStatusAboutMe(
            data.street.length > 0 &&
            data.number.length > 0 &&
            data.postalCode.length > 0 &&
            data.city.length > 0 &&
            data.birthday?.length > 0
        )
        save(data);
    };

    useEffect(() => {
        if(user) {
            setInitialData(user)
        }
    }, [user]);

    useEffect(() => {
        if (initialData) {
            methods.reset(initialData);
            setInitialData(false);
        }
    }, [initialData, methods]);

    const save = async (data) => {
        try {
            const url = new URL("/api/applicant", window.location + "");
            await fetch(url, {
                headers: { "Content-Type": "application/json" },
                method: "POST",
                body: JSON.stringify(data),
            });
            toast(t('aboutMe.saved'), { type: "success", autoClose: 1000, position: "top-right" });
            
            
            setTimeout(() => {
                history.push("/meine-dokumente");
            }, 1);
        } catch (err) {
            console.log(err);
            toast(t('aboutMe.errorSaving'), "error");
        }
    };

    const MailField = useCallback(() => {
        return (
            <TextField
                label={t('aboutMe.mail')}
                size={"small"}
                variant={"filled"}
                defaultValue={user?.mail}
                placeholder={'Keine E-Mail angegeben'}
                className={"w-100"}
                InputLabelProps={{ shrink: true }}
                disabled
            />
        );
    }, [t, user?.mail]);

    return (
        <div>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <h1 className="heading">{t('aboutMe.title')}</h1>

                    <Typography>{t('aboutMe.p1')}</Typography>

                    <div className="form-grid mt-4">

                        <div className="formHeader"><FontAwesomeIcon icon={faMapLocation} fixedWidth /> Adresse</div>

                        <div className="twoSmallEnd ">
                            {createInput(
                                "street",
                                t('aboutMe.street'),
                                methods.control,
                                methods.formState.errors,
                                { className: "w-100", disabled: props.disabled }
                            )}
                            {createInput(
                                "number",
                                t('aboutMe.number'),
                                methods.control,
                                methods.formState.errors,
                                { className: "w-short", disabled: props.disabled }
                            )}
                        </div>

                        <div className="twoSmallStart">
                            {createInput(
                                "postalCode",
                                t('aboutMe.postalCode'),
                                methods.control,
                                methods.formState.errors,
                                { className: "w-short", disabled: props.disabled }
                            )}
                            {createInput(
                                "city",
                                t('aboutMe.city'),
                                methods.control,
                                methods.formState.errors,
                                { className: "w-100", disabled: props.disabled }
                            )}
                        </div>

                        <div className="formHeader mt-4"><FontAwesomeIcon icon={faBirthdayCake} fixedWidth /> Geburtstag</div>

                        <div className="one">
                            {createDatePicker(
                                "birthday",
                                t('aboutMe.birthday'),
                                methods.control,
                                methods.formState.errors,
                                { className: "m-0 birthdaypicker", disabled: props.disabled }
                            )}
                        </div>

                        <div className="formHeader mt-4"><FontAwesomeIcon icon={faPhone} fixedWidth /> Kontaktaufnahme</div>

                        <div className="two">
                            {createInput(
                                "phone",
                                t('aboutMe.phone'),
                                methods.control,
                                methods.formState.errors,
                                {},
                                { className: "w-100", disabled: props.disabled }
                            )}

                            {createInput(
                                "mobile",
                                t('aboutMe.mobile'),
                                methods.control,
                                methods.formState.errors,
                                {},
                                { className: "w-100", disabled: props.disabled }
                            )}

                        </div>


                        <div className="two">
                            <MailField />
                            {createInput(
                                "callbackVia",
                                t('aboutMe.callback'),
                                methods.control,
                                methods.formState.errors,
                                {},
                                { className: "w-100", disabled: props.disabled }
                            )}
                        </div>

                        <div className="formHeader mt-4"><FontAwesomeIcon icon={faLanguage} fixedWidth /> Sprachkenntnisse</div>


                        <div className={"one"}>
                            <LanguageSelector disabled={props.disabled} />
                        </div>
                    </div>

                    <Button
                        className={"mt-5"}
                        color={"primary"}
                        variant={"contained"}
                        //onClick={() => saveForm(saveButtonTarget)}
                        type={"submit"}

                    >
                        {t('aboutMe.next')}
                    </Button>
                </form>

            </FormProvider>
        </div>
    );
}

export default FormAboutMe;
