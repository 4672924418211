import React, { useState, createContext } from "react";

const NetworkChangesContext = createContext();

function NetworkChangesProvider({ children }) {
    // eslint-disable-next-line no-unused-vars
    const [lastUpdate, setLastUpdate] = useState(Date.now());

    const update = () => {
        setLastUpdate(Date.now());
    };

    return <NetworkChangesContext.Provider value={{ update, lastUpdate }}>{children}</NetworkChangesContext.Provider>;
}

export { NetworkChangesProvider, NetworkChangesContext };
