import React, { useEffect, useState, createContext } from 'react'
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoon, faSun } from '@fortawesome/pro-solid-svg-icons';
import { createTheme, ThemeProvider as MUIThemeProvicer } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';


const ThemeContext = createContext()

function ThemeProvider({ children }) {

    const { t } = useTranslation('');
    const [lightMode, setLightMode] = useState(localStorage.getItem('style') !== "false");

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    primary: {
                        light: '#ff6748',
                        main: '#d5311d',
                        dark: '#9c0000',
                        contrastText: '#fff',
                    },
                    secondary: {
                        light: '#fffffe',
                        main: '#f5ebcb',
                        dark: '#c2b99a',
                        contrastText: '#000',
                    },
                    type: lightMode ? 'light' : 'dark'
                }
            }),
        [lightMode],
    );

    useEffect(() => {
        localStorage.setItem('style', lightMode);
    }, [lightMode])

    const navSwitch = () => {
        return (

            <div className="menu-item" onClick={() => toggle()}>
                <span className="nav-icon text-muted"><FontAwesomeIcon fixedWidth icon={lightMode ? faMoon : faSun} /></span>
                <span className="text-muted">{lightMode ? t('navigation.dark') : t('navigation.light')}</span>
            </div>
        )
    }

    const toggle = () => {
        setLightMode(!lightMode)
    }

    return (
        <ThemeContext.Provider value={{ navSwitch, toggle }}>
            <MUIThemeProvicer theme={theme}>
                <CssBaseline />
                {children}
            </MUIThemeProvicer>
        </ThemeContext.Provider>
    )
}


export { ThemeProvider, ThemeContext }