import React, { useCallback, useEffect, useState, useContext } from "react";
import { useTranslation } from 'react-i18next';
import {
    faArrowCircleLeft,

    faEllipsisV,
    faThList,
    faTimes,
    faIdCardClip,
    faHeart
} from "@fortawesome/pro-solid-svg-icons";
import { faCalendar, faMap } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Chip,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from "@material-ui/core";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { NetworkChangesContext } from "./../core/networkChanges"
import MuiAlert from "@material-ui/lab/Alert";
import { StatusContext } from "../core/contextStatus";

function RecruitmentProcess() {

    const { t } = useTranslation();

    const [workflows, setWorkflows] = useState([]);
    const { update } = useContext(NetworkChangesContext);

    const [formComplete, setFormComplete] = useState({});
    const [hasFiles, setHasFiles] = useState(-1);
    const { statusComplete, statusAboutMe, statusApplied, statusFiles } = useContext(StatusContext)

    const loadWorkflows = useCallback(async () => {
        const url = new URL("/api/workflows", window.location + "");
        const response = await fetch(url, { headers: { "Content-Type": "application/json" }, method: "GET" });
        const result = await response.json();
        setWorkflows(result);
    }, []);

    const cancelWorkflow = async (id) => {
        try {
            setAnchorEl({});
            const url = new URL("/api/workflows/" + id, window.location + "");
            const response = await fetch(url, {
                headers: { "Content-Type": "application/json" },
                method: "DELETE"
            });
            await response.json();
            await loadWorkflows();
            toast(t('applicationState.cancelWorkflow.success'), { type: "success" });
            update();
        } catch (err) {
            toast(t('applicationState.cancelWorkflow.error'), { type: "error" });
        }
    };



    const restartWorkflow = async (id) => {
        try {
            setAnchorEl({});
            const url = new URL("/api/workflows/" + id + "/restart", window.location + "");
            await fetch(url, { headers: { "Content-Type": "application/json" }, method: "PUT" });
            await loadWorkflows();
            toast(t('applicationState.restartWorkflow.success'), { type: "success" });
            update();
        } catch (err) {
            toast(t('applicationState.restartWorkflow.error'), { type: "error" });
        }
    };

    useEffect(() => {
        loadWorkflows();
    }, [loadWorkflows]);

    const theme = useTheme();
    const states = {
        active: <Chip label="aktiv" style={{ backgroundColor: theme.palette.success.main }} />,
        successed: <Chip label="beendet" style={{ backgroundColor: theme.palette.info.main }} />,
        canceled: <Chip label="beendet" style={{ backgroundColor: theme.palette.warning.main }} />,
        usercanceled: <Chip label="beendet" style={{ backgroundColor: theme.palette.warning.main }} />,
        userdeleted: <Chip label="beendet" style={{ backgroundColor: theme.palette.warning.main }} />,
        incomplete:  <Chip label="NICHT VOLLSTÄNDIG" style={{ backgroundColor: theme.palette.warning.main }} />,
    };

    const eventsTranslation = {
        'workflowInitiation': t('applicationState.events.workflowInitiation'),
        'invitedToInterview': t('applicationState.events.invitedToInterview'),
        'workflowStopped': t('applicationState.events.workflowStopped'),
        'rejected': t('applicationState.events.rejected'),
        'accepted': t('applicationState.events.accepted'),
    }

    const StyledMenu = withStyles({})((props) => (
        <Menu
            elevation={2}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            {...props}
        />
    ));

    const StyledMenuItem = withStyles((theme) => ({
        root: {},
    }))(MenuItem);

    const [anchorEl, setAnchorEl] = useState({});

    const handleClick = (event, id) => {
        setAnchorEl({ [id]: event.currentTarget });
    };

    const handleClose = () => {
        setAnchorEl({});
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const InterviewInfo = (_props) => {
        return <div className={_props.className}>
            <FontAwesomeIcon icon={faCalendar} fixedWidth /> {t('applicationState.interviewInfo.at')} {format(new Date(_props.event.eventData?.date), 'dd.MM.yyyy HH:mm')} {t('applicationState.interviewInfo.clock')}<br />
            <FontAwesomeIcon icon={faMap} fixedWidth /> {_props.event.eventData?.where}<br />
        </div>
    }

    return (
        <div>
            <h1 className="heading">{t('applicationState.title')}</h1>

            {!workflows ||
                (workflows?.length === 0 && (
                    <Typography component={"p"} className={"mb-3"}>
                        {t('applicationState.noWorkflows.0')}{" "}
                        <Link to="stellen">
                            <FontAwesomeIcon fixedWidth icon={faThList} /> {t('applicationState.noWorkflows.1')}
                        </Link>{" "}
                        {t('applicationState.noWorkflows.2')}
                    </Typography>
                ))}

            {workflows &&
                (workflows?.length > 0 && (
                    <Typography component={"p"} className={"mb-3"}>
                        {t('applicationState.workflows.0')}{" "}
                        <Link to="stellen">
                            <FontAwesomeIcon fixedWidth icon={faThList} /> {t('applicationState.workflows.1')}
                        </Link>{" "}
                        {t('applicationState.workflows.2')}
                    </Typography>
                ))}

            {/*!statusAboutMe && (
                <Alert severity="warning" className="mb-4">
                    {t('applicationState.notComplete.0')} <FontAwesomeIcon fixedWidth icon={faIdCardClip} /> {t('applicationState.notComplete.1')}
                </Alert>
            )*/}

            {!statusFiles && (
                <Alert severity="info" className="mb-4">
                    {t('applicationState.noFiles')}
                </Alert>
            )}

            {workflows?.length > 0 && (
                <div>
                    <Typography component={"p"} className={"mb-3"}>
                        {t('applicationState.workflows.p1')}
                    </Typography>

                    <TableContainer component={Paper}>
                        <Table aria-label="Offene Stellen">
                           
                            <TableBody>
                                {workflows?.map((row) => (
                                    <React.Fragment key={row._id}>
                                        <TableRow>
                                            <TableCell scope="row">
                                                <b>{row.job.title}</b>
                                            </TableCell>
                                            <TableCell>{format(new Date(row.job.startDate), "dd.MM.")}</TableCell>
                                            <TableCell>{(typeof row.job.department === 'object' && row.job.department.constructor === Object) && <div>
                                                {row.job.department?.name}<br />
                                                <small>{row.job.department?.street} {row.job.department?.housenumber}, {row.job.department?.postalcode} {row.job.department?.city}</small>
                                            </div>}
                                                {(typeof row.job.department === 'string' || row.job.department instanceof String) && row.job.department}</TableCell>
                                            <TableCell>{states[statusComplete ? row.state : 'incomplete']}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    aria-label="more"
                                                    aria-controls="workflowmenu"
                                                    aria-haspopup="true"
                                                    onClick={(e) => handleClick(e, row._id)}
                                                >
                                                    <FontAwesomeIcon icon={faEllipsisV} fixedWidth />
                                                </IconButton>
                                                <StyledMenu
                                                    id={"workflowmenu_" + row._id}
                                                    anchorEl={anchorEl[row._id]}
                                                    keepMounted
                                                    open={Boolean(anchorEl[row._id])}
                                                    onClose={handleClose}
                                                >
                                                    <ListItem>
                                                        <ListItemText primary={t('applicationState.workflows.actions')} />
                                                    </ListItem>

                                                    {row.state === "active" && (
                                                        <StyledMenuItem onClick={() => cancelWorkflow(row._id)}>
                                                            <ListItemIcon>
                                                                <FontAwesomeIcon icon={faTimes} />
                                                            </ListItemIcon>
                                                            <ListItemText primary={t('applicationState.workflows.cancel')} />
                                                        </StyledMenuItem>
                                                    )}
                                                    {row.state === "usercanceled" && (
                                                        <StyledMenuItem onClick={() => restartWorkflow(row._id)}>
                                                            <ListItemIcon>
                                                                <FontAwesomeIcon icon={faArrowCircleLeft} />
                                                            </ListItemIcon>
                                                            <ListItemText primary={t('applicationState.workflows.restart')} />
                                                        </StyledMenuItem>
                                                    )}
                                                </StyledMenu>
                                            </TableCell>
                                        </TableRow>
                                        {row.events.map(event => {
                                            return <TableRow key={event._id}>
                                                <TableCell colSpan={5} className={'p-2 pl-5'} >
                                                    <div> {eventsTranslation[event.type] || event.type}</div>
                                                    {event.type === 'invitedToInterview' && <InterviewInfo className={'ml-2 mt-2'} event={event} />}
                                                    {event.type === 'workflowInitiation' && <div className={'ml-2 mt-2'} event={event}>
                                                        <FontAwesomeIcon icon={faCalendar} fixedWidth /> am {format(new Date(event.createdDate), 'dd.MM.yyyy')}<br />
                                                    </div>}
                                                    {(event.type === 'workflowStopped' || event.type === 'rejected') && <div className={'ml-2 mt-2'} event={event}>
                                                        <FontAwesomeIcon icon={faCalendar} fixedWidth /> am {format(new Date(event.createdDate), 'dd.MM.yyyy')}<br />
                                                    </div>}
                                                    {event.type === 'accepted' && <div className={'ml-2 mt-2'}>
                                                        <div><FontAwesomeIcon icon={faCalendar} fixedWidth /> am {format(new Date(event.createdDate), 'dd.MM.yyyy')}</div>
                                                        <div className="text-danger"><FontAwesomeIcon icon={faHeart} fixedWidth /> {t('applicationState.success')}</div>
                                                    </div>}
                                                </TableCell>
                                            </TableRow>
                                        })}
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            )}
        </div>
    );
}

export default RecruitmentProcess;
