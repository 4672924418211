import { useContext, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from "react-hook-form";
import { Button, TextField } from "@material-ui/core";
import { LoginContext } from "../core/contextLogin";
import { createInput } from "../core/creaters.js";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { Turnstile } from '@marsidev/react-turnstile'
import { Alert } from "@material-ui/lab";

function Register(props) {

    const { t, i18n } = useTranslation();
    const { register } = useContext(LoginContext);
    const history = useHistory();
    const [useLinkInfo, setUseLinkInfo] = useState(false);

    const {
        control,
        handleSubmit,
        watch,
        setValue,
        register: registerField,
        formState: { isDirty, isValid, errors },
    } = useForm({
        mode: "all",
        defaultValues: {
            mail: "",
            password: "",
            password2: "",
            surname: "",
            givenname: "",
            turnstile: ""
        },
    });

    const password = useRef({});
    password.current = watch("password", "");

    registerField('turnstile', { required: true });

    const onSubmit = async (data) => {
        try {
            const result = await register({ ...data, jobs: props.markedAsApplied });
            if (result.success === false && result.alreadyRegistered) {
                setUseLinkInfo(true)
            } else if (result.success === false) {
                throw new Error(result.message)
            } else {
                toast(t("register.successful"), { type: "success" });
                history.push('/ueber-mich')
                localStorage.removeItem('markedAsApplied')
            }
        } catch (err) {
            toast(err.message, { type: "error" });
        }
    };

    return (
        <div>
            <p>{t("register.p1")}</p>

            {useLinkInfo && <Alert color="info" severity="info">
                <div className="large">{t('register.alreadyApplied')} <b>{t('register.alreadyApplied2')}</b></div>
            </Alert>}

            {!useLinkInfo && <form onSubmit={handleSubmit(onSubmit)} className={"form-grid"}>



                <div className="two">
                    {createInput("surname", t('register.surname'), control, errors, { required: true }, {})}
                    {createInput("givenname", t('register.givenname'), control, errors, { required: true }, {})}
                </div>

                <div className="one">
                    <Controller
                        name="mail"
                        control={control}
                        rules={{ required: false, pattern: /^\S+@\S+$/i }}
                        render={({ field }) => (
                            <TextField
                                label={t('register.mailAddress')}
                                type={"email"}
                                size={"small"}
                                {...field}
                                variant={"filled"}
                                className={"w-100"}
                            />
                        )}
                    />
                </div>

                <Turnstile options={{ theme: 'light', language: 'de' }} siteKey={(process.env.NODE_ENV === 'production' ? '0x4AAAAAAADHhQgP_yTqBMa9' : '1x00000000000000000000AA')} onSuccess={(token) => setValue("turnstile", token)} />

                <div className="one">
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        disabled={!isDirty || !isValid}
                        className={"bp3-button mt-3"}
                        type="submit"
                    >
                        {t('register.registerNow')}
                    </Button>
                </div>
            </form>}
        </div>
    );
}

export default Register;
