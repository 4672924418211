import React from "react";
import { Route } from "react-router-dom";

import FormAboutMe from "./pages/Form-AboutMe.js"
import Imprint from "./pages/Imprint.js";
import Privacy from "./pages/Privacy.js";
import Jobs from "./pages/Jobs.js";
import RecruitmentProcess from "./pages/RecruitmentProcess.js";
import Settings from "./pages/Settings.js";
import Login from "./components/Login.js";
import Help from "./pages/Help.js";
import RegisterLogin from "./components/RegisterLogin.js";
import FormMyDocuments from "./pages/Form-MyDocuments.js";
import FormMiscellaneous from "./pages/Form-Miscellaneous.js";
import GDPR from "./pages/GDPR.js";
import Campaign from "./pages/Campaign.js";

function router() {
    return (
        <React.Fragment>

            <Route path="/gdpr-decision/:uuid/:decision">
                <GDPR />
            </Route>


            <Route path="/kampagne/:uuid/:participantUuid" exact>
                <Campaign leave />
            </Route>


            <Route path="/kampagne/:uuid" exact>
                <Campaign />
            </Route>

            <Route path="/anmelden">
                <RegisterLogin />
            </Route>

            <Route path="/login/:uuid">
                <Login />
            </Route>

            <Route path="/ueber-mich">
                <FormAboutMe />
            </Route>

            <Route path="/meine-dokumente">
                <FormMyDocuments />
            </Route>

            <Route path="/weiteres">
                <FormMiscellaneous />
            </Route>

            <Route path="/status-der-bewerbung">
                <RecruitmentProcess />
            </Route>

            <Route path="/stellen">
                <Jobs />
            </Route>

            <Route path="/einstellungen">
                <Settings />
            </Route>

            <Route path="/impressum">
                <Imprint />
            </Route>

            <Route path="/datenschutz">
                <Privacy />
            </Route>

            <Route path="/hilfe">
                <Help />
            </Route>

            <Route path="/" exact>
                <Jobs />
            </Route>

        </React.Fragment>
    );
}

export default router;
