import { useContext, useEffect, useState, useCallback } from "react";
import { TextField, Button } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { LoginContext } from "../core/contextLogin";
import { useParams, useHistory } from "react-router";
import { toast } from "react-toastify";
import { NetworkChangesContext } from "../core/networkChanges.js";
import MuiAlert from "@material-ui/lab/Alert";
import { Turnstile } from '@marsidev/react-turnstile'

function Login(props) {
    const { uuid } = useParams();
    const history = useHistory();

    const [processing, setProcessing] = useState(false);
    const { login, tokenLogin } = useContext(LoginContext);
    const { update } = useContext(NetworkChangesContext);
    const [message, setMessage] = useState(false);
    const [messageType, setMessageType] = useState('info');

    const { control, handleSubmit, register: registerField, setValue } = useForm();

    registerField('turnstile', { required: true });


    const onSubmit = async (data) => {
        try {
            const result = await login(data.mail, data.password, props.password, data.turnstile);
            if (result.success) {
                await update();
                toast("Erfolgreich angemeldet!", { type: "success" });
                history.push("/status-der-bewerbung");
            }
            if (!result.success) {
                setMessage(result.message)
                setMessageType(result.type)
            }

        } catch (err) {
            console.log("Login-Error: ", err);
            setMessage(err.message);
            setMessageType('error')
        }
    };


    const startTokenLogin = useCallback(async (uuid) => {
        try {
            const result = await tokenLogin(uuid);
            if (result.success) {
                await update();
                toast("Erfolgreich angemeldet!", { type: "success" });
                history.push("/status-der-bewerbung");
            }
            if (!result.success) {
                setMessage(result.message)
                setMessageType(result.type)
                setProcessing(false)
            }
        } catch (err) {
            console.log("Login-Error: ", err);
            setMessage(err.message);
            setMessageType('error')
        }

    }, []);

    useEffect(() => {
        if (uuid) {
            setProcessing(true);
            startTokenLogin(uuid);
        }
    }, [uuid, startTokenLogin]);

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    if (uuid) {


        return (<div>
            {processing && <>Bitte warten, die Anmeldung läuft...</>}
            {message && <div className="one">
                <Alert severity={'error'} className="mb-4">
                    {message}
                </Alert>
            </div>}
        </div>)

    } else {

        return (
            <div>

                {props.password && <p>Melden Sie sich hier mit ihrer E-Mail Adresse und ihrem Kennwort an.</p>}
                {!props.password && (
                    <p>
                        Melden Sie sich hier mit ihrer E-Mail Adresse an. Sie erhalten einen Anmeldelink per E-Mail.
                    </p>
                )}


                <form onSubmit={handleSubmit(onSubmit)} className={"form-grid"}>
                    <div className="one">
                        <Controller
                            name="mail"
                            control={control}
                            defaultValue=""
                            rules={{ required: true, pattern: /^\S+@\S+$/i }}
                            render={({ field }) => (
                                <TextField
                                    label="E-Mail Adresse"
                                    type={"email"}
                                    size={"small"}
                                    {...field}
                                    variant={"filled"}
                                    className={"w-50"}
                                />
                            )}
                        />
                    </div>
                    {props.password && (
                        <div className="one">
                            <div>
                                <Controller
                                    name="password"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: true,
                                        minLength: {
                                            value: 6,
                                            message: "Das Kennwort muss mindestens 6 Zeichen lang sein.",
                                        },
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            label="Kennwort"
                                            type={"password"}
                                            size={"small"}
                                            className={"w-50"}
                                            {...field}
                                            variant={"filled"}
                                        />
                                    )}
                                />
                            </div>

                            <div>
                                <p className="m-1 mb-4">
                                    <span className="text-muted small pointer">Kennwort vergessen?</span>
                                </p>
                            </div>
                        </div>
                    )}


                    {message && <div className="one">
                        <Alert severity={messageType} className="mb-4">
                            {message}
                        </Alert>
                    </div>}

                    <div className="one">
                        <Turnstile options={{ theme: 'light', language: 'de' }} siteKey={(process.env.NODE_ENV === 'production' ? '0x4AAAAAAADHhQgP_yTqBMa9' : '1x00000000000000000000AA')} onSuccess={(token) => setValue("turnstile", token)} />
                    </div>

                    <Button variant={"contained"} color={"primary"} type="submit">
                        Anmelden
                    </Button>

                </form>



            </div>
        );


    }
}

export default Login;
