import { Checkbox, FormControl, FormControlLabel, InputLabel, NativeSelect, Radio, RadioGroup, TextField } from "@material-ui/core"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { Controller } from "react-hook-form"
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/de"
import React, {Suspense} from "react";

const createCustomSelect = (name, label, options, onChange, defaultValue) =>
    <FormControl>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <NativeSelect inputProps={{ id: name, name }} onChange={onChange} defaultValue={defaultValue}>
            <option aria-label="None" value="" />
            {options.map(o => <option value={o.value}>{o.label}</option>)}
        </NativeSelect>
    </FormControl>


const createSelect = (name, label, control, options, index = 0, fieldOptions) =>
    <FormControl>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <Controller
            render={({ field }) => (
                <NativeSelect {...field} {...fieldOptions} variant={'filled'} inputProps={{ id: name }}>
                    <option aria-label="None" value="" />
                    {options.map(o => <option key={name + '_' + index + '_' + o.value} value={o.value}>{o.label}</option>)}
                </NativeSelect>
            )}
            name={name}
            control={control}
        />
    </FormControl>


const createDatePicker = (name, label, control, errors, rules = { required: false }, fieldOptions) =>
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { ref, ...rest } }) => (
                <KeyboardDatePicker
                    margin="normal"
                    inputVariant={'filled'}
                    label={label}
                    format="dd.MM.yyyy"
                    variant={'inline'}
                    disableToolbar
                    size={'small'}
                    error={errors.hasOwnProperty(name)}
                    emptyLabel={''}
                    InputLabelProps={{ shrink: true }}
                    {...fieldOptions}
                    {...rest}
                />
            )}
        />
    </MuiPickersUtilsProvider>


const createInput = (name, label, control, errors, rules = { required: false }, fieldOptions) => {
    return <Suspense fallback="loading">
        <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => <TextField
            label={label}
            size={'small'}
            variant={'filled'}
            error={errors.hasOwnProperty(name)}
            {...field}
            {...fieldOptions}
        />
        }
    /></Suspense>
}



const createRadios = (name, label, control, radios, fieldOptions, defaultValue) => {
    return <Controller
        defaultValue={defaultValue}
        render={({ field }) => (
            <RadioGroup row aria-label={label} {...field} >
                {radios.map((r) => <FormControlLabel {...r} key={name + '_' + r.value} {...fieldOptions} control={<Radio color={'primary'} size={'small'} />} />)}
            </RadioGroup>
        )}
        name={name}
        control={control}
    />
}

const createCheckboxes = (name, control, fieldOptions) => {

    return <Controller
        name={name}
        control={control}
        render={({ field }) => (
            <Checkbox
                onChange={(e) => field.onChange(e.target.checked)}
                checked={field.value}
                {...fieldOptions}
            />
        )}
    />
}



export { createCustomSelect, createSelect, createDatePicker, createInput, createRadios, createCheckboxes }