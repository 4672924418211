import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import { LoginContext } from "../core/contextLogin";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEnvelope, faFire, faMinus, faPhone, faPlus, faStar, faUser, faTrophy } from "@fortawesome/pro-solid-svg-icons";
import { } from "@fortawesome/pro-duotone-svg-icons";
import React from "react";
import { toast } from "react-toastify";
import { NetworkChangesContext } from "./../core/networkChanges";
import { useHistory } from "react-router";
import Register from "../components/Register.js";
import { StatusContext } from "../core/contextStatus";

function Jobs() {

    const history = useHistory();
    const { t } = useTranslation();

    const [jobs, setJobs] = useState([]);
    const [workflows, setWorkflows] = useState([]);
    const [view] = useState("table");
    const [filter, setFilter] = useState("");
    const { authenticated } = useContext(LoginContext);
    const [moreInfo, setMoreInfo] = useState(undefined);
    const [markedAsApplied, setMarkedAsApplied] = useState(JSON.parse(localStorage.getItem("markedAsApplied")) || []);
    const { setStatusApplied } = useContext(StatusContext)


    const { update } = useContext(NetworkChangesContext);

    const loadJobs = useCallback(async () => {
        try {
            const url = new URL("/api/jobs", window.location + "");
            const response = await fetch(url, {
                headers: { "Content-Type": "application/json" },
                method: "GET",
            });
            const result = (await response.json()).sort((a, b) => {
                if (a.highlight && !b.highlight) return -1;
                if (!a.highlight && b.highlight) return 1;
                return 0;
            });
            
            if (result?.length > 0 && markedAsApplied?.length > 0) {
                try {
                    setMarkedAsApplied(markedAsApplied.filter(m => result.map(j => j._id).includes(m)));
                } catch (err) { }
            }
            setJobs(result);
        } catch (err) {
            console.error(err);
            toast("Da ist was schiefgegangen! Versuche es später noch einmal!", { type: "error" });
        }
    }, []);

    const loadWorkflows = useCallback(async () => {
        const url = new URL("/api/workflows", window.location + "");
        const response = await fetch(url, {
            headers: { "Content-Type": "application/json" },
            method: "GET",
        });
        const result = await response.json();
        setStatusApplied(result.length > 0)
        setWorkflows(result);
    }, [setStatusApplied]);

    const applyToJob = async (job) => {
        if (authenticated) {
            try {
                const url = new URL("/api/workflows/" + job + "/apply", window.location + "");
                const response = await fetch(url, {
                    headers: { "Content-Type": "application/json" },
                    method: "POST",
                });
                await response.json();
                await loadWorkflows();
                toast("Das Stellenbesetzungsverfahren wurde erfolgreich gestartet.", { type: "success" });
                update();
            } catch (err) {
                toast("Da ist was schiefgegangen! Versuche es später noch einmal!", { type: "error" });
            }
        } else {
            if (markedAsApplied.indexOf(job) > -1) {
                let updatedMarkedAsApplied = [...markedAsApplied];
                updatedMarkedAsApplied.splice(markedAsApplied.indexOf(job), 1);
                setMarkedAsApplied(updatedMarkedAsApplied);
            } else {
                setMarkedAsApplied([...markedAsApplied, job]);
            }
            setStatusApplied(markedAsApplied.length > 0)
            setTimeout(() => {
                document.getElementById("appliedDiv").scrollIntoView();
            }, 100)
        }
    };

    useEffect(() => {
        localStorage.setItem("markedAsApplied", JSON.stringify(markedAsApplied));
    }, [markedAsApplied]);

    useEffect(() => {
        loadJobs();
    }, [loadJobs]);

    useEffect(() => {
        if (authenticated) loadWorkflows();
    }, [authenticated, loadWorkflows]);

    const BewerbenButton = (props) => {
        const check = <FontAwesomeIcon icon={faCheck} fixedWidth />;

        /*
        Wenn nicht authenticated: 
            - Bewerbung aktivieren // Bewerbung zurückziehen

        Wenn authenticated:
            - Bereits beworben // Bewerbung aktivieren
        */

        return (
            <Button
                size={"small"}
                style={{ minWidth: 10, padding: 3, paddingLeft: 6, paddingRight: 6 }}
                variant={(props.applied ? "contained" : "outlined")}
                color={"primary"}
                disabled={authenticated && (props.applied || props.disabled)}
                onClick={() => applyToJob(props._id)}
            >
                {authenticated && (props.applied ? t("allJobs.table.alreadyApplied") : t("allJobs.table.apply"))}
                {!authenticated && (props.applied ? <>{check}&nbsp;&nbsp;{t("allJobs.table.choosed")}</> : t("allJobs.table.choose"))}
            </Button>
        );
    };

    const MoreInfo = (props) => {
        const selected = props._id === moreInfo;
        const onChange = () => {
            if (selected) setMoreInfo(undefined);
            else setMoreInfo(props._id);
        };
        return (
            <Button onClick={onChange} size={"small"} variant={selected ? "contained" : "outlined"} color="primary" style={{ padding: 5, minWidth: 0 }}>
                {/*selected ? t("allJobs.table.hide") : t("allJobs.table.more")*/}
                {selected ? <FontAwesomeIcon fixedWidth icon={faMinus} /> : <FontAwesomeIcon fixedWidth icon={faPlus} />}

            </Button>
        );
    };



    return (
        <div className={"w-100"}>
            <h1 className="heading">{t("allJobs.title")}</h1>

            <Typography component={"p"} className={"mb-3"}>
                {t("allJobs.p1")}
            </Typography>

            <div className="mb-4">
                <Button className={'mr-2 mb-2'} size={"small"} variant={filter === "" ? "contained" : "outlined"} onClick={(e) => setFilter("")} color="primary">Alle</Button>
                {jobs.map(j => j.department?.city).filter((value, index, array) => array.indexOf(value) === index).map(j => {
                    if (j)
                        return <Button key={"filter_" + j} className={'mr-2 mb-2'} size={"small"} variant={filter === j ? "contained" : "outlined"} onClick={(e) => setFilter(j)} color="primary">{j}</Button>
                })}
            </div>

            {view === "table" && (
                <TableContainer component={Paper}>
                    <Table aria-label="Offene Stellen" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>{t("allJobs.table.job")}</TableCell>
                                <TableCell>{t("allJobs.table.when")}</TableCell>
                                <TableCell>{t("allJobs.table.where")}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {jobs.filter(f => filter === "" || f.department === filter || f.department?.city === filter || f.department?.name === filter).map((row) => {
                                const _applied =
                                    (authenticated && workflows?.map((w) => w.job._id).indexOf(row._id) > -1) ||
                                    (!authenticated && markedAsApplied.indexOf(row._id) > -1);


                                return (
                                    <React.Fragment key={row._id}>
                                        <TableRow selected={row._id === moreInfo}>
                                            <TableCell>
                                                <MoreInfo _id={row._id} />
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.highlight && <FontAwesomeIcon fixedWidth icon={faTrophy} bounce style={{ color: 'red' }} className="mr-1" />}
                                                <span style={{ fontWeight: row.highlight ? 'bold' : 'normal' }}>
                                                    {row.title}
                                                </span>
                                            </TableCell>
                                            <TableCell>{(new Date(row.startDate) < new Date() ? 'Sofort' : format(new Date(row.startDate), "dd.MM."))}</TableCell>
                                            <TableCell>
                                                {(typeof row.department === 'object' && row.department.constructor === Object) && <div>
                                                    {row.department?.name}<br />
                                                    <small>{row.department?.street} {row.department?.housenumber}, {row.department?.postalcode} {row.department?.city}</small>
                                                </div>}
                                                {(typeof row.department === 'string' || row.department instanceof String) && row.department}
                                            </TableCell>

                                            <TableCell>
                                                <BewerbenButton _id={row._id} applied={_applied} />
                                            </TableCell>
                                        </TableRow>
                                        {moreInfo === row._id && (
                                            <TableRow>
                                                <TableCell className={"jobMoreInfo py-3"} colSpan={5}>
                                                    <div
                                                        className={"job-info-text"}
                                                        dangerouslySetInnerHTML={{ __html: row.description }}
                                                    ></div>
                                                    <div className="mt-4">
                                                        <p><b>Ansprechpartner</b></p>
                                                        <FontAwesomeIcon fixedWidth icon={faUser} /> {row.callbacks?.name}
                                                        {row.callbacks?.number && <><br /><FontAwesomeIcon fixedWidth icon={faPhone} /> {row.callbacks.number}</>}
                                                        {row.callbacks?.mail && <><br /><FontAwesomeIcon fixedWidth icon={faEnvelope} /> <a href={'mailto:' + row.callbacks.mail}>{row.callbacks.mail}</a></>}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {!authenticated && markedAsApplied.length > 0 && (
                <div className="mt-5" id={'appliedDiv'}>
                    <Register markedAsApplied={markedAsApplied} />
                </div>
            )}

            {authenticated && (
                <Button
                    className={"mt-5"}
                    color={"primary"}
                    variant={"contained"}
                    onClick={() => {
                        history.push("/ueber-mich");
                    }}
                >
                    {t("buttons.next")}
                </Button>
            )}
        </div>
    );
}

export default Jobs;
