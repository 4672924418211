import { AppBar, Box, Tab, Tabs } from '@material-ui/core';
import { useState } from 'react';
import Login from './Login';

function RegisterLogin() {

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>

            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="Login mit E-Mail oder mit Passwort">
                    <Tab label="Anmelden (E-Mail)" />
                    <Tab label="Anmelden (Passwort)" />
                </Tabs>
            </AppBar>
           
            <TabPanel value={value} index={0}>
                <Login />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Login password={true} />
            </TabPanel>


        </div>

    )

}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default RegisterLogin;