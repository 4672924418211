import React from "react";
import { useTranslation } from 'react-i18next';
import { faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, FormControl, InputLabel, NativeSelect } from "@material-ui/core";
import classNames from "classnames";
import { useFormContext, useWatch } from "react-hook-form";
import { createInput } from "../core/creaters.js";

function LanguageSelects(props) {

    const { t } = useTranslation();
    const { register, setValue, control } = useFormContext(); // retrieve all hook methods

    const index = props.index || 0;

    const languagesOptions = [
        { value: "german", label: "deutsch" },
        { value: "english", label: "englisch" },
        { value: "spanish", label: "spanisch" },
        { value: "other", label: "andere" },
    ];

    const languageTypesOptions = [
        { value: "mother", label: t('languageSelector.mother')},
        { value: "fluent", label: t('languageSelector.fluent') },
        { value: "good", label: t('languageSelector.good') },
        { value: "okay", label: t('languageSelector.basic') },
    ];

    register("language." + index + ".type", "");
    register("language." + index + ".language", "");

    const onChange = (value, langtype, currentLanguage, currentType, currentId) => {
        setValue("language." + index, {
            language: currentLanguage,
            type: currentType,
            _id: currentId,
            [langtype]: value,
        });
    };

    const selectedLang = useWatch({
        control,
        name: "language." + index + ".language",
    });

    const selectedType = useWatch({
        control,
        name: "language." + index + ".type",
    });

    const selectedId = useWatch({
        control,
        name: "language." + index + "._id",
    });

    return (
        <div className={classNames("two mb-3", props.className)}>
            <FormControl>
                <InputLabel>{t('languageSelector.language')}</InputLabel>
                <NativeSelect
                    disabled={props.disabled}
                    onChange={(e) => onChange(e.target.value, "language", selectedLang, selectedType, selectedId)}
                    value={selectedLang}
                >
                    <option aria-label="None" value="" />
                    {languagesOptions.map((o) => (
                        <option key={index + "lang" + o.value} value={o.value}>
                            {o.label}
                        </option>
                    ))}
                </NativeSelect>
            </FormControl>

            {selectedLang !== "other" && (
                <FormControl>
                    <InputLabel>{t('languageSelector.knowledge')}</InputLabel>
                    <NativeSelect
                        disabled={props.disabled}
                        onChange={(e) => onChange(e.target.value, "type", selectedLang, selectedType, selectedId)}
                        value={selectedType}
                    >
                        <option aria-label="None" value="" />
                        {languageTypesOptions.map((o) => (
                            <option key={index + "lang" + o.value} value={o.value}>
                                {o.label}
                            </option>
                        ))}
                    </NativeSelect>
                </FormControl>
            )}

            {selectedLang === "other" && (
                <FormControl>
                    {createInput("language." + index + ".detail", "Andere", control, {}, {}, { variant: "standard", disabled: props.disabled })}
                </FormControl>
            )}
        </div>
    );
}

function LanguageSelector(props) {
    const { setValue, watch } = useFormContext(); // retrieve all hook methods
    const language = watch("language");
    const length = language?.length;

    const add = () => {
        setValue("language", [...watch("language"), { type: "", language: "" }]);
    };

    const del = () => {
        let _langs = watch("language");
        _langs.pop();
        setValue("language", _langs);
        //if (languages > 1) setValue("languages", languages - 1);
    };

    return (
        <div>
            {new Array(length).fill().map((e, i) => (
                <LanguageSelects  key={"ls_" + i} index={i} disabled={props.disabled} />
            ))}
            <Button
                disabled={length > 2 || props.disabled}
                variant={"contained"}
                color={"secondary"}
                className={"mt-3"}
                size={"small"}
                onClick={add}
            >
                <FontAwesomeIcon icon={faPlus} fixedWidth />
            </Button>
            <Button
                disabled={length < 2 || props.disabled}
                variant={"contained"}
                color={"secondary"}
                className={"ml-3 mt-3"}
                size={"small"}
                onClick={del}
            >
                <FontAwesomeIcon icon={faMinus} fixedWidth />
            </Button>
        </div>
    );
}

export default LanguageSelector;
