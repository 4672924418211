import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { faEnvelope, faPhone, faSmile, faSpinnerThird, faUser } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router";
import { Alert } from "@material-ui/lab";
import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, NativeSelect, Radio, RadioGroup, TextField } from "@material-ui/core"
import Grid from '@material-ui/core/Grid';


function Campaign(props) {

    const [campaign, setCampaign] = useState({});
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [surname, setSurname] = useState("de Buhr");
    const [givenname, setGivenname] = useState("Daniel");
    const [email, setEmail] = useState("danieldebuhr@gmail.com");
    const [phone, setPhone] = useState("0174789456");
    const { uuid, participantUuid } = useParams();

    const createCampaign = async () => {
        try {
            const url = new URL("/api/campaign", window.location + "");

            const response = await fetch(url, {
                headers: { "Content-Type": "application/json" },
                method: "POST",
                body: JSON.stringify({
                    title: "Eine Test-Kampagne",
                    campaignStartDate: "2023-09-01",
                    campaignEndDate: "2023-09-30",
                    jobDate: "2023-10-01",
                    // a fancy description with html styled text about a fictive campaign
                    description: `<p style="font-size: 1.2em; text-align: justify;">
                    Du bist technikaffin und hast ein Händchen für Computer und Netzwerke? Starte deine Karriere mit unserer <strong>IT Systemadministrator Ausbildung!</strong> Während deiner Ausbildung lernst du, IT-Systeme zu installieren, zu konfigurieren und zu warten, Netzwerkprobleme zu lösen und vieles mehr.
                </p>
                <p style="font-size: 1.1em; text-align: justify;">
                    Bleib immer auf dem neuesten Stand und sichere dir einen Platz in einem wachsenden und zukunftssicheren Berufsfeld. Melde dich für unseren Newsletter an und lass dich vormerken, sobald die Bewerbungsphase für unsere Ausbildungsplätze beginnt.
                </p>`,
                    visible: true,
                    responsibility: "64ada23a974f3463c63974e5",
                    callbacks: {
                        "name": "Daniel de Buhr",
                        "number": "0174789456",
                        "mail": "test@caritas-ol.de"
                    },
                    department: {
                        originId: "64adbdbf37f262347e69181c",
                        name: "Test",
                        street: "Teststraße",
                        housenumber: "10",
                        postalcode: "26169",
                        city: "Testhausen"
                    },

                })
            });

            if (response.status !== 200) {
                throw new Error("Error while creating campaign")
            }

            const data = await response.json();
            console.log(data)


        } catch (err) {
            console.log("Error: ", err);

        }
    }

    const loadCampaign = async (uuid) => {
        try {
            const url = new URL("/api/campaign/" + uuid, window.location + "");

            const response = await fetch(url, {
                headers: { "Content-Type": "application/json" },
                method: "GET"
            });

            if (response.status !== 200) {
                throw new Error("Error while loading campaign")
            }

            const data = await response.json();

            setCampaign(data)

            setProcessing(false)

        } catch (err) {
            console.log("Error: ", err);
            setError(err)
            setProcessing(false)
        }
    }

    const saveParticipation = async (uuid, data) => {

        try {

            const url = new URL("/api/campaign/" + uuid, window.location + "");

            const response = await fetch(url, {
                headers: { "Content-Type": "application/json" },
                method: "POST",
                body: JSON.stringify(data)
            });

            if (response.status !== 200) {
                throw new Error("Error while saving participation")
            }

            setProcessing(false)
            setSuccess(true)

        } catch (err) {
            console.log("Error: ", err);
            setError(err)
            setProcessing(false)
        }
    }

    const leaveCampaign = async (uuid) => {
        try {

            const url = new URL("/api/campaign/" + uuid + '/' + participantUuid, window.location + "");

            const response = await fetch(url, {
                headers: { "Content-Type": "application/json" },
                method: "DELETE",
            });

            if (response.status !== 200) {
                throw new Error("Error while saving participation")
            }

            setProcessing(false)
            setSuccess(true)

        } catch (err) {
            console.log("Error: ", err);
            setError(err)
            setProcessing(false)
        }

    }

    const handleSurnameChange = (event) => {
        setSurname(event.target.value);
    }

    const handleGivennameChange = (event) => {
        setGivenname(event.target.value);
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    }

    useEffect(() => {
        if (props.leave) {
            leaveCampaign(uuid);
        } else {
            loadCampaign(uuid);
        }
    }, [uuid]);


    const errors = {}

    if (props.leave) {

        return (
            <div>

                {
                    processing && <Alert severity="info" icon={false} className="mt-4">
                        <FontAwesomeIcon icon={faSpinnerThird} fixedWidth spin /> Bitte warten, deine Teilnahme wird gelöscht...
                    </Alert>
                }

                {
                    !processing && error && <Alert severity="error" className="mt-4">
                        Beim Löschen der Teilnahme ist ein Fehler aufgetreten. Bitte versuche es später erneut.
                    </Alert>
                }

                {
                    !processing && !error && success && <div className="mt-4">
                        <h3>Schade, dass du nicht mehr Teilnehmen möchtest.</h3>
                        <p>Wir wünschen dir trotzdem alles Gute auf deinem Weg! Vielleicht auf Wiedersehen.</p>
                    </div>
                }


            </div>
        );

    } else {
        return (
            <div>

                {
                    //<button onClick={() => createCampaign()}>Create Campaign</button>
                }

                <h1>{campaign.title}</h1>

                {campaign.titlePicture && <div><img src={campaign.titlePicture} alt={campaign.title} /></div>}
                {!campaign.titlePicture && <div><img src={'/it.png'} style={{ maxHeight: '200px' }} alt={campaign.title} /></div>}

                <div className="mt-4" dangerouslySetInnerHTML={{ __html: campaign.description }}></div>

                <div className="mt-4">
                    <p><b>Standort</b></p>
                    {campaign.department?.name}<br />
                    {campaign.department?.street} {campaign.department?.housenumber}, {campaign.department?.postalcode} {campaign.department?.city}
                </div>

                <div className="mt-4">
                    <p><b>Ansprechpartner</b></p>
                    <FontAwesomeIcon fixedWidth icon={faUser} /> {campaign.callbacks?.name}
                    {campaign.callbacks?.number && <><br /><FontAwesomeIcon fixedWidth icon={faPhone} /> {campaign.callbacks.number}</>}
                    {campaign.callbacks?.mail && <><br /><FontAwesomeIcon fixedWidth icon={faEnvelope} /> <a href={'mailto:' + campaign.callbacks.mail}>{campaign.callbacks.mail}</a></>}
                </div>

                {
                    processing && <Alert severity="info" icon={false} className="mt-4">
                        <FontAwesomeIcon icon={faSpinnerThird} fixedWidth spin /> Bitte warten, deine Teilnahme wird gespeichert...
                    </Alert>
                }

                {
                    !processing && error && <Alert severity="error" className="mt-4">
                        Beim Verarbeiten der Teilnahme ist ein Fehler aufgetreten. Bitte versuche es später erneut.
                    </Alert>
                }

                {
                    !processing && !error && success && <Alert severity="success" icon={false} className="mt-4">
                        <FontAwesomeIcon icon={faSmile} fixedWidth /> Vielen Dank für deine Teilnahme! Wir werden uns bei dir melden.
                    </Alert>
                }

                {
                    !processing && !error && !success && <div className="mt-4">

                        <p><b>Teilnehmen</b></p>

                        <p>Konnten wir dein Interesse wecken? Dann trage dich hier ein - wir werden uns bei dir melden!</p>

                        <form>

                            <div className="my-3">


                                <div className="mt-4">
                                    <p><b>Teilnehmen</b></p>
                                    <p>Konnten wir dein Interesse wecken? Dann trage dich hier ein - wir werden uns bei dir melden!</p>
                                    <form>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    label={'Nachname'}
                                                    name={'surname'}
                                                    value={surname}
                                                    onChange={handleSurnameChange}
                                                    variant={'filled'}
                                                    error={errors.hasOwnProperty('surname')}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    label={'Vorname'}
                                                    name={'givenname'}
                                                    value={givenname}
                                                    onChange={handleGivennameChange}
                                                    variant={'filled'}
                                                    error={errors.hasOwnProperty('givenname')}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    label={'Telefon / Handy'}
                                                    name={'phone'}
                                                    value={phone}
                                                    onChange={handlePhoneChange}
                                                    variant={'filled'}
                                                    error={errors.hasOwnProperty('phone')}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    label={'E-Mail'}
                                                    name={'email'}
                                                    value={email}
                                                    onChange={handleEmailChange}
                                                    variant={'filled'}
                                                    error={errors.hasOwnProperty('email')}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Button variant="contained" color="primary" onClick={() => saveParticipation(uuid, { surname, givenname, email, phone })}>
                                                    Tailnahme speichern
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </div>

                            </div>

                        </form>
                    </div>
                }

            </div >
        );

    }

}

export default Campaign;
