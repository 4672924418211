import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router";
import { Alert } from "@material-ui/lab";

function GDPR() {

    const [processing, setProcessing] = useState(true);
    const [error, setError] = useState(false);
    const { uuid, decision } = useParams();

    const saveDecision = async (uuid, decision) => {
        
        try {

            const url = new URL("/api/gdpr-decision/" + uuid + "/" + decision, window.location + "");
            console.log(url)

            const response = await fetch(url, {
                headers: { "Content-Type": "application/json" },
                method: "GET"
            });

            if (response.status !== 200) {
                throw new Error("Error while saving decision")
            }

            setProcessing(false)

        } catch (err) {
            console.log("gdpr-Error: ", err);
            setError(err)
            setProcessing(false)
        }
    }

    useEffect(() => {
        saveDecision(uuid, decision);
    }, [uuid, decision]);

    return (
        <div>

            {processing && <Alert severity="info" icon={false}>
                <FontAwesomeIcon icon={faSpinnerThird} fixedWidth spin /> Bitte warten, deine Entscheidung wird verarbeitet...
            </Alert>}

            {!processing && error && <Alert severity="error">
                Beim Verarbeiten deiner Entscheidung ist ein Fehler aufgetreten. Bitte versuche es später erneut.
            </Alert>}

            {!processing && !error && <Alert severity="success" >
                {decision === "yes" && <div>Vielen Dank für deine Mitteilung! Deine Bewerbung bleibt weiterhin im Portal.</div>}
                {decision === "no" && <div>Mit großem Bedauern nehmen wir deine Entscheidung zur Kenntnis und wünschen dir bei deinem zukünftigen Werdegang alles Gute!</div>}
            </Alert>}

        </div>
    );

}

export default GDPR;
