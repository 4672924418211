import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState, createContext, useContext, useCallback} from 'react'
import { useTranslation } from 'react-i18next';
import { LoginContext } from './contextLogin';

const StatusContext = createContext()

function StatusProvider({ children }) {

    const [statusApplied, setStatusApplied] = useState(false)
    const [statusAboutMe, setStatusAboutMe] = useState(false)
    const [statusMiscellaneous, setStatusMiscellaneous] = useState(false)
    const [statusFiles, setStatusFiles] = useState(false)
    const [statusComplete, setStatusComplete] = useState(false)

    const { t } = useTranslation('');

    const { user } = useContext(LoginContext);

    useEffect(() => {
        setStatusComplete(statusApplied)
    }, [statusApplied, statusAboutMe])

    const load = useCallback(async (_user) => {

        setStatusAboutMe(
            _user.street?.length > 0 &&
            _user.number?.length > 0 &&
            _user.postalCode?.length > 0 &&
            _user.city?.length > 0 &&
            _user.birthday?.length > 0
        )

        setStatusMiscellaneous(
            _user?.hearedAboutUs?.length > 0 || _user.apprenticeQualification === true || _user.apprenticeQualification === false || _user.rating > -1
        );

        const url_workflows = new URL("/api/workflows", window.location + "");
        const response_workflows = await fetch(url_workflows, {
            headers: { "Content-Type": "application/json" },
            method: "GET",
        });
        const result_workflows = await response_workflows.json();
        if (result_workflows.error) return;

        setStatusFiles(_user?.files?.length > 0);
        setStatusApplied(result_workflows?.filter((w) => w.state === "active").length > 0);
    }, []);

    // Initial load
    useEffect(() => {
        if(user) load(user);
    }, [user, load])

    return (
        <StatusContext.Provider value={{
            statusAboutMe, statusApplied, statusFiles, statusComplete, statusMiscellaneous, setStatusAboutMe, setStatusApplied, setStatusFiles, setStatusMiscellaneous
        }}>
            {children}
        </StatusContext.Provider>
    )
}


export { StatusProvider, StatusContext }