function Privacy() {

    return (
        <div>
            <h1 className="heading">Datenschutz</h1>

            <h2>Information für Bewerber nach § 15 des Gesetzes über den Kirchlichen Datenschutz (KDG)</h2>

            <p>Datenschutzerklärung für das Online-Bewerbungsverfahren</p>

            <p>Wir freuen uns über Ihr Interesse an unserer Stellenausschreibung und einer Zusammenarbeit mit der Caritas Oldenburg. Die Caritas Oldenburg nimmt den Datenschutz ernst. Daher informieren wir Sie hier über die Erhebung, Verarbeitung und Löschung Ihrer personenbezogenen Daten im Zuge des Bewerbungsverfahrens. Die Verarbeitung Ihrer Daten bzw. Bewerbungsunterlagen erfolgt im Rahmen der gesetzlichen Vorschriften.</p>
            
            <p><b>Verarbeitungszweck:</b></p>
            <p>Im Zuge des Online-Bewerbungsverfahrens werden von uns personenbezogene Daten erhoben und verarbeitet. Die elektronische Erhebung, Speicherung, Verarbeitung und Nutzung der personenbezogenen Bewerbungsdaten erfolgen  zum Zweck des Bewerbungsverfahrens.</p>
            
            <p><b>Rechtsgrundlage:</b></p>
            <p>Bei der Bewerbung verarbeiten wir Daten von Ihnen, die wir im Rahmen der Bewerbung benötigen. Dies können Kontaktdaten, alle mit der Bewerbung in Verbindung stehenden Daten (Lebenslauf, Zeugnisse, Qualifikationen, Antworten auf Fragen etc.) sowie ggf. Daten zur Bankverbindung (um Reisekosten zu erstatten) sein. Die Rechtsgrundlage hierfür ergibt sich aus § 53 KDG.</p>
            <p>Erhebung, Speicherung, Verarbeitung und Nutzung Ihrer personenbezogenen Bewerbungsdaten sind für die Entscheidung über die Begründung eines Beschäftigungsverhältnisses erforderlich.</p>
            
            <p><b>Verantwortliche Stelle:</b></p>
            <p>Für die Datenerhebung und -verarbeitung ist das Unternehmen verantwortlich, bei dem Sie sich beworben haben.</p>
            
            <p><b>Datenlöschung:</b></p>
            <p>Soweit keine gesetzliche Aufbewahrungsfrist existiert, werden die Daten gelöscht, sobald eine Speicherung nicht mehr erforderlich, bzw. das berechtigte Interesse an der Speicherung erloschen ist. Sofern keine Einstellung erfolgt, ist dies regelmäßig spätestens sechs Monate nach Abschluss des Bewerbungsverfahrens der Fall.</p>
            <p>In Einzelfällen kann es zu einer längeren Speicherung von einzelnen Daten kommen (z. B. Reisekostenabrechnung). Die Dauer der Speicherung richtet sich dann nach den gesetzlichen Aufbewahrungspflichten bspw. aus der Abgabenordnung (6 Jahre) oder dem Handelsgesetzbuch (10 Jahre). </p>
            <p>Sofern es nicht zu einer Einstellung gekommen ist, Ihre Bewerbung aber weiterhin für uns interessant ist, fragen wir Sie, ob wir Ihre Bewerbung für künftige Stellenbesetzungen weiter vorhalten dürfen. </p>
            <p>Bei Erfolg Ihrer Bewerbung werden Ihre personenbezogenen Daten Bestandteil Ihrer Personalakte und zur Begründung, Durchführung und Beendigung des Beschäftigungsverhältnisses verarbeitet und nach den für Personalakten geltenden Regelungen gelöscht.</p>
            
            <p><b>Vertrauliche Behandlung Ihrer Daten:</b></p>
            <p>Bitte beachten Sie, dass Ihre Bewerbungsdaten ausschließlich zum Zweck des Bewerbungsverfahrens verarbeitet werden. Ihre Daten und Bewerbungsunterlagen werden hierzu an die am Bewerbungsverfahre beteiligten internen Stellen und betroffenen Fachabteilungen der Caritas Oldenburg weitergeleitet. </p>
            <p>In bestimmten Fällen müssen wir Ihre personenbezogenen Daten Dritten offenlegen, beispielsweise der Bank, wenn Sie eine Kostenerstattung erhalten oder der Post, wenn wir per Brief mit Ihnen kommunizieren. Ggf. setzen wir auch streng weisungsgebundene Dienstleister ein, die uns z. B. in den Bereichen EDV oder der Archivierung und Vernichtung von Dokumenten unterstützen und mit denen gesonderte Verträge zur Auftragsverarbeitung geschlossen wurden. </p>
            <p>Eine kommerzielle Weitergabe der Daten an Dritte erfolgt nicht.</p>
            
            <p><b>Ihre Datenschutzrechte:</b></p>
            <p>Als betroffene Person haben Sie das Recht auf Auskunft über die Sie betreffenden personenbezogenen Daten sowie auf Berichtigung unrichtiger Daten oder auf Löschung, sofern einer der in § 19 KDG genannten Gründe vorliegt, z.B. wenn die Daten für die verfolgten Zwecke nicht mehr benötigt werden. Es besteht zudem das Recht auf Einschränkung der Verarbeitung, wenn eine der in Art. 20 KDG genannten Voraussetzungen vorliegt und in den Fällen des Art. 22 KDG das Recht auf Datenübertragbarkeit. </p>
            <p>Jede betroffene Person hat das Recht auf Beschwerde bei einer Aufsichtsbehörde, wenn sie der Ansicht ist, dass die Verarbeitung der sie betreffenden Daten gegen datenschutzrechtliche Bestimmungen verstößt. Das Beschwerderecht kann insbesondere bei einer Aufsichtsbehörde in dem Mitgliedstaat des Aufenthaltsorts oder des Arbeitsplatzes der betroffenen Person oder des Orts des mutmaßlichen Verstoßes geltend gemacht werden.  </p>
            
            <p><b>Unser Datenschutzbeauftragter:</b></p>
            <p>Sie haben zudem das Recht, sich jederzeit an unseren Datenschutzbeauftragten zu wenden, der bezüglich Ihrer Anfrage zur Verschwiegenheit verpflichtet ist. Die Kontaktdaten unseres Datenschutzbeauftragten lauten:</p>

            <p>Dr. Uwe Schläger<br />
                datenschutz nord GmbH<br />
                Konsul-Smidt-Straße 88<br />
                28217 Bremen<br /><br />
                Tel.: +49 (0) 421  69 66 32-0<br />
                Fax: +49 (0) 421  69 66 32-11<br />
                E-Mail: kirche@datenschutz-nord.de<br />
                Web: www.datenschutz-nord-gruppe.de</p>

            <p>Nähere Informationen stellen wir Ihnen gerne auf Anfrage zur Verfügung.</p>


        </div>
    );
}

export default Privacy;
