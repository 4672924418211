import React, { useState, createContext, useEffect, useCallback } from "react";

const LoginContext = createContext();

function LoginProvider({ children }) {
    // eslint-disable-next-line no-unused-vars
    const [user, setUser] = useState();

    // eslint-disable-next-line no-unused-vars
    const [token, setToken] = useState("");

    // eslint-disable-next-line no-unused-vars
    const [authenticated, setAuthenticated] = useState(false);

    const getApplicant = useCallback(async () => {
        try {
            const url = new URL("/api/applicant", window.location + "");
            const response = await fetch(url, { headers: { "Content-Type": "application/json" }, method: "GET" });
            if (response.ok) {
                const result = await response.json();
                setLogin(result);
                return result;
            }
        } catch (err) {
            //console.log(err)
        }
    }, []);

    useEffect(() => {
        getApplicant();
    }, [getApplicant]);

    const logout = async () => {
        try {
            const url = new URL("/api/auth/logout", window.location + "");
            await fetch(url, { headers: { "Content-Type": "application/json" }, method: "GET" });
        } catch (err) {
            console.log(err);
        }
        setUser({});
        setAuthenticated(false);
    };

    const setLogin = (data) => {
        setUser(data);
        setAuthenticated(true);
    };

    const login = async (mail, password, usePassword, turnstile) => {
        const url = new URL("/api/auth/login", window.location + "");
        const response = await fetch(url, {
            headers: { "Content-Type": "application/json" },
            method: "POST",
            body: JSON.stringify({ mail, password, usePassword: usePassword ? 'true' : 'false', turnstile }),
        });
        if (response.status !== 200) {
            const result = await response.json();
            throw new Error(result.message);
        } else {
            const result = await response.json();
            if (result.success && result.applicant) {
                setLogin(result.applicant)
            }
            return result
        }
    };

    const tokenLogin = async (token) => {
        await new Promise((resolve) => setTimeout(resolve, 1000));

        const url = new URL("/api/auth/login/" + token, window.location + "");
        const response = await fetch(url, { headers: { "Content-Type": "application/json" }, method: "GET" });

        if (response.status !== 200) {
            const result = await response.json();
            throw new Error(result.message);
        } else {
            const result = await response.json();
            if (result.success && result.applicant) {
                setLogin(result.applicant)
            }
            return result
        }

    };

    const register = async (data) => {
        if (data.mail === "") delete data.mail
        const url = new URL("/api/auth/register", window.location + "");
        const response = await fetch(url, {
            headers: { "Content-Type": "application/json" },
            method: "POST",
            body: JSON.stringify(data),
        });

        if (response.status !== 200) {
            const result = await response.json();
            throw new Error(result.message);
        } else {
            const result = await response.json();
            if (result.error) {
                throw new Error("Fehler bei der Registrierung.");
            }
            if (result.success === false && result.alreadyRegistered) {
                return { success: false, message: result.message, alreadyRegistered: true }
            }
            if (result.success === false) {
                throw new Error(result.message)
            }
            if (result.success) {
                if (result.success && result.applicant) {
                    setLogin(result.applicant)
                }
                return result
            }
        }

    };

    const createPassword = async (data) => {
        const url = new URL("/api/auth/set-password", window.location + "");
        const response = await fetch(url, {
            headers: { "Content-Type": "application/json" },
            method: "POST",
            body: JSON.stringify(data),
        });
        if (response.status !== 200) {
            const result = await response.json();
            throw new Error(result.message);
        } else {
            const result = await response.json();
            return result;
        }
    };

    return (
        <LoginContext.Provider
            value={{ user, authenticated, token, createPassword, logout, login, tokenLogin, register, getApplicant }}
        >
            {children}
        </LoginContext.Provider>
    );
}

export { LoginProvider, LoginContext };
