import React, { useContext } from "react"
import { LoginContext } from "../core/contextLogin"

import { useTranslation } from 'react-i18next';

function WhoAmI() {

    const { t } = useTranslation();
    const { authenticated, user } = useContext(LoginContext)

    if(!authenticated) return (<React.Fragment />)

    return (
        <p className="ml-2 pb-0 pt-0">{t('welcome')}, {user.givenname}.</p>
    )
}

export default WhoAmI