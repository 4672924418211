import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { LoginProvider } from "./core/contextLogin";
import { NetworkChangesProvider } from "./core/networkChanges";
import { ThemeProvider as ContextThemeProvider } from "./core/contextTheme";
import './i18n';
import { StatusProvider } from "./core/contextStatus";
import { BrowserRouter as Router } from 'react-router-dom'

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback="loading">
            <NetworkChangesProvider>
                <ContextThemeProvider>
                    <LoginProvider>
                        <StatusProvider>
                            <Router>
                                <App />
                            </Router>
                        </StatusProvider>
                    </LoginProvider>
                </ContextThemeProvider>
            </NetworkChangesProvider>
        </Suspense>
    </React.StrictMode>,
    document.getElementById("root")
);
