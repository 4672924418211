import './App.scss';
import React, { useState, useEffect } from "react";
import Navigation from './components/Navigation.js'
import PageRouter from './router.js'
import WhoAmI from './components/Whoami';
//import NavigationStatus from './components/NavigationStatus';
import NavigationLower from './components/NavigationLower';
import classnames from 'classnames'
import NavigationLoginLogout from './components/NavigationLoginLogout';
import NavigationLanguage from './components/NavigationLanguage';
import { Flip, ToastContainer } from "react-toastify";
import 'fontsource-roboto';
import "./Forms.scss";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { useLocation } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-solid-svg-icons';
import { useTheme } from "@material-ui/core";


function App() {

    const location = useLocation();
    const [responsiveNavShow, setResponsiveNavShow] = useState(false);
    const theme = useTheme();

    const showNavigation = !(
        location.pathname.indexOf("/gdpr-decision") > -1 ||
        location.pathname.indexOf("/kampagne") > -1
    )

    useEffect(() => {
        setResponsiveNavShow(false)
    }, [location.pathname])

    return (


        <div className={classnames("App")}>

            <ToastContainer position="top-center" transition={Flip} />

            <div className="container">

                {showNavigation && <div className={classnames('nav-bar', (theme.palette.type === 'dark' ? 'dark' : ''))}>
                    <div className="title">
                        <img alt="Caritas Oldenburg" src='/caritas-logo.png' />
                        <div>Karriere</div>
                    </div>
                    <div className="pointer burger" onClick={(e) => setResponsiveNavShow(!responsiveNavShow)}>
                        <FontAwesomeIcon icon={faBars} />
                    </div>
                </div>}

                {showNavigation && <div className={classnames("nav-wrapper", (theme.palette.type === 'dark' ? 'dark' : ''), (responsiveNavShow ? 'show-responsive' : ''))}>
                    <div className="nav">

                        <div className="nav-title">
                            <div className="logo">
                                <img alt="Caritas Oldenburg" src='/caritas-logo.png' />
                            </div>

                            <div className="title">
                                Karriere
                            </div>
                        </div>

                        <div className="divider"></div>

                        <div className="nav-parts">
                            <WhoAmI />
                            <Navigation />
                            <NavigationLoginLogout />
                            <NavigationLower />
                            <NavigationLanguage />
                        </div>

                    </div>
                </div>}

                <main role="main" className="flex-fill">
                    <div className="page">

                        <PageRouter />

                    </div>
                </main>

            </div>
        </div>
    );

}

export default App;
